import React from "react";

// markup
const ProfilePage = ({allWpCareerPostData}) => {
  const careersPostArr = allWpCareerPostData.data.allWpCareer.edges;
  const CarrierCardList = ()=> {
    const careersToBeRendered = [];

      for (let i in careersPostArr) {
        let data = careersPostArr[i].node;
        careersToBeRendered.push(
          <div className="p-carrier-card" key={i}>
            <h3 className="p-carrier-card__title">
              <span className="period">{data.careers.period}</span>
              <span className="title">{data.title}</span>
            </h3>
            <dl className="p-carrier-card__body">
              <dt>社名</dt>
              <dd>{data.careers.companyName}</dd>
              <dt>事業内容</dt>
              <dd>{data.careers.businessDetail}</dd>
              <dt>担当業務</dt>
              <dd>{data.careers.role}</dd>
            </dl>
          </div>
        )
      }

    return (
      <>
        {careersToBeRendered}
      </>
      
    )
  }

  return (
    <>
      <section>
        <h2 className="c-page-section-title">
          <span>About Me</span>
        </h2>
        <dl className="p-techs-and-tools__list">
          <dt>Name</dt>
          <dd>
            <span>竹永　範夫</span>
          </dd>
          <dt>Born in</dt>
          <dd>1978.06.29</dd>
          <dt>Live in</dt>
          <dd>
            <span>広島市</span>
          </dd>
          <dt>Skills</dt>
          <dd>
            マークアップ及びフロントエンド開発、CMS（WordPress）多言語化を含むテーマ開発及びプラグイン開発、ウェブ解析など
          </dd>
          <dt>Certificate</dt>
          <dd>WACA上級ウェブ解析士</dd>
        </dl>
      </section>
      <section>
        <h2 className="c-page-section-title">
          <span>Techs and Tools</span>
        </h2>
        <dl className="p-techs-and-tools__list">
          <dt>HTML and CSS</dt>
          <dd>
            <ul className="p-techs-and-tools__list__items">
              <li>EJS,Pug</li>
              <li>SCSS(w/Bootstrap,Bulma)</li>
            </ul>
          </dd>
          <dt>JavaScript</dt>
          <dd>
            <ul className="p-techs-and-tools__list__items">
              <li>ES6</li>
              <li>jQuery</li>
              <li>Vue</li>
              <li>React</li>
            </ul>
          </dd>
          <dt>PHP</dt>
          <dd>
            <ul className="p-techs-and-tools__list__items">
              <li>WordPress</li>
            </ul>
          </dd>
          <dt>Build Tool/Task runner</dt>
          <dd>
            <ul className="p-techs-and-tools__list__items">
              <li>Gulp</li>
              <li>Webpack</li>
            </ul>
          </dd>
          <dt>Versioning Tools</dt>
          <dd>
            <ul className="p-techs-and-tools__list__items">
              <li>Git</li>
            </ul>
          </dd>
          <dt>Local Development Tools</dt>
          <dd>
            <ul className="p-techs-and-tools__list__items">
            <li>Vagrant</li>
            <li>Local (&amp; Local by Flywheel)</li>
            </ul>
          </dd>
          <dt>Editor</dt>
          <dd>
            <ul className="p-techs-and-tools__list__items">
              <li>Visual Studio Code</li>
            </ul>
          </dd>
        </dl>
        <dl className="p-techs-and-tools__list">
          <dt>Design Tools</dt>
          <dd>
            <ul className="p-techs-and-tools__list__items">
              <li>Sketch</li>
              <li>Adobe XD</li>
              <li>Adobe Photoshop</li>
              <li>Adobe Illustrator</li>
            </ul>
          </dd>
        </dl>
        <dl className="p-techs-and-tools__list">
          <dt>PCs</dt>
          <dd>
            <ul className="p-techs-and-tools__list__items">
              <li>Mac</li>
              <li>Windows</li>
            </ul>
          </dd>
        </dl>
      </section>
      <section>
        <h2 className="c-page-section-title">
          <span>Carrier</span>
        </h2>
        <CarrierCardList />
      </section>
    </>
  );
};

export default ProfilePage;
