import * as React from "react";
import { graphql } from "gatsby";
import { useQueryParam, ArrayParam } from "use-query-params";

import AuthContent from "../components/AuthContent";
import Layout from "../components/Layout";
import ProfilePage from "../components/Profile";

export const query = graphql`
  query {
    allWpCareer(sort: {fields: id, order: DESC}) {
      edges {
        node {
          title
          databaseId
          careers {
            role
            period
            fieldGroupName
            companyName
            businessDetail
          }
        }
      }
    }
  }
`
const Profile = ( data )=> {
  return (
    <Layout pageTitle="Profile" pageSlug="profile">
      <AuthContent>
        <ProfilePage allWpCareerPostData={data}  />
      </AuthContent>
    </Layout>
  );
}

export default Profile;
